import React, { Component } from 'react';
import { Link } from 'gatsby';
import {
  SiteHeader,
  SiteNav,
  SiteTitle,
  SiteLinks,
  ShowMobile,
  HideMobile,
  MenuButton,
} from '../styles/headerStyles';
import { Menu } from 'react-feather';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const menuItems = [
  {
    path: '/',
    name: 'Home',
  },
  {
    path: '/talks',
    name: 'Talks',
  },
  {
    path: '/podcast',
    name: 'Podcast',
  },
  {
    path: '/blog',
    name: 'Blog',
  },
];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '<VR>',
      menuExpanded: false,
    };
    this.expand = this.expand.bind(this);
    this.contract = this.contract.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }
  expand() {
    this.setState({ name: '<VinayRaghu>' });
  }
  contract() {
    this.setState({ name: '<VR>' });
  }
  openMenu() {
    this.setState({ menuExpanded: true });
  }
  closeMenu() {
    this.setState({ menuExpanded: false });
  }
  render() {
    return (
      <SiteHeader>
        <SiteNav>
          <SiteTitle>
            <Link
              to="/"
              onMouseEnter={this.expand}
              onMouseLeave={this.contract}
            >
              {this.state.name}
            </Link>
          </SiteTitle>
          <SiteLinks>
            <ShowMobile>
              <MenuButton>
                <Menu onClick={this.openMenu} size={30} />
              </MenuButton>
              {this.state.menuExpanded && (
                <MobileMenu menuItems={menuItems} closeMenu={this.closeMenu} />
              )}
            </ShowMobile>
            <HideMobile>
              <DesktopMenu menuItems={menuItems} />
            </HideMobile>
          </SiteLinks>
        </SiteNav>
      </SiteHeader>
    );
  }
}

export default Header;
