import React from 'react';
import { Link } from 'gatsby';
import { MenuLinks, MenuLink } from '../styles/headerStyles';

const DesktopMenu = ({ menuItems }) => (
  <MenuLinks>
    {menuItems.map(item => (
      <MenuLink key={item.path}>
        <Link to={item.path}>{item.name}</Link>
      </MenuLink>
    ))}
  </MenuLinks>
);

export default DesktopMenu;
