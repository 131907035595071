import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Colors, Container, TextCenter } from '../styles/globalStyles';
import {
  SiContentful,
  SiNetlify,
  SiReact,
  SiGooglefonts,
  SiUnsplash,
  SiGatsby,
  SiGraphql,
  SiSimpleicons,
} from 'react-icons/si';
import { StaticQuery, graphql, Link } from 'gatsby';

const Footer = styled.footer`
  background: ${Colors.black};
  padding: 60px 0;
  color: ${Colors.white};
  min-height: 40vh;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const FeaturedCardGrid = styled.section`
  display: grid;
  margin-top: 30px;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  @media (min-width: 560px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  grid-gap: 30px;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const FeatureCard = styled.article`
  background-color: #111;
  transition: all 0.4s ease-in-out;
  &:hover {
    background-color: #444;
  }
  border-radius: 12px;
  padding: 15px;
  h4 {
    margin-top: 15px;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  a {
    color: #f55539;
  }
`;

const FooterCopy = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 45%;
    margin: 0 auto;
    text-align: center;
  }
  font-size: 14px;

  a {
    color: ${Colors.red};
  }
`;

const SlashVR = styled.div`
  padding: 15px 0 0 0;
  background: ${Colors.white};
  font-size: 30px;
  line-height: 60px;
  margin: 0;
  font-weight: bold;
  a {
    color: ${Colors.white};
    text-decoration: none;
  }
`;

const SiteFooter = ({ version }) => {
  const [name, setName] = useState('VR');
  return (
    <div>
      <Footer>
        <Container>
          <div className="text-center">
            <TextCenter>
              <h3>Built with passion...</h3>
            </TextCenter>
            <FeaturedCardGrid>
              <FeatureCard>
                <SiReact size={48} />
                <h4>React</h4>
                <p>
                  Used mainly for the{' '}
                  <a href="/blog/thoughts-on-why-i-dont-miss-react#templating">
                    JSX templating
                  </a>
                  , client-side libraries and job secruity.
                </p>
              </FeatureCard>
              <FeatureCard>
                <SiGatsby size={48} />
                <h4>Gatsby</h4>
                <p>
                  To enable static site generation and optimize page load
                  performance.
                </p>
              </FeatureCard>
              <FeatureCard>
                <SiGraphql size={48} />
                <h4>GraphQL</h4>
                <p>For data-fetching from multiple sources.</p>
              </FeatureCard>
              <FeatureCard>
                <SiContentful size={48} />
                <h4>Contentful</h4>
                <p>
                  CMS to store all data that is powering this website except for
                  blogs, which are markdown files.
                </p>
              </FeatureCard>
              <FeatureCard>
                <SiNetlify size={48} />
                <h4>Netlify</h4>
                <p>
                  For static site hosting, handling form submissions and having
                  CI/CD integrated with Github.
                </p>
              </FeatureCard>
              <FeatureCard>
                <SiSimpleicons size={48} />
                <h4>Icons</h4>
                <p>
                  From{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://feathericons.com/"
                  >
                    Feather
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://simpleicons.org/"
                  >
                    simple icons
                  </a>{' '}
                  via{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://react-icons.github.io/react-icons/icons"
                  >
                    React icons
                  </a>
                  .
                </p>
              </FeatureCard>
              <FeatureCard>
                <SiUnsplash size={48} />
                <h4>Images</h4>
                <p>
                  From{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://unsplash.com/"
                  >
                    unsplash
                  </a>{' '}
                  when they are not my own.
                </p>
              </FeatureCard>
              <FeatureCard>
                <SiGooglefonts size={48} />
                <h4>Fonts</h4>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://fonts.google.com/specimen/Titillium+Web"
                  >
                    Titillium Web
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://fonts.google.com/specimen/Poppins"
                  >
                    Poppins
                  </a>{' '}
                  hosted via{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://fonts.google.com/"
                  >
                    Google fonts
                  </a>
                  .
                </p>
              </FeatureCard>
            </FeaturedCardGrid>
            <FooterCopy>
              <p>
                ..and other fun technologies. All code is hosted on Github as a
                private repository. Development is done on VS-Code. If you are
                interested, take a look at my{' '}
                <Link to="/blog/setup-a-new-macbook">
                  preferred dev machine setup
                </Link>
                . Fueled by coffee and{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.lofi.cafe/"
                >
                  lo-fi beats
                </a>
                . Current active version is{' '}
                <Link to="/changelog">v{version}</Link>.
              </p>
            </FooterCopy>
          </div>
        </Container>
      </Footer>
      <SlashVR>
        <Container
          onMouseEnter={() => setName('VinayRaghu')}
          onMouseLeave={() => setName('VR')}
        >{`</${name}>`}</Container>
      </SlashVR>
    </div>
  );
};

const FooterDataQuery = () => (
  <StaticQuery
    query={graphql`
      {
        package {
          version
        }
      }
    `}
    render={(data) => <SiteFooter version={data.package.version} />}
  />
);

export default FooterDataQuery;
