import React from 'react';
import { Link } from 'gatsby';
import {
  MobileMenuBackground,
  MobileMenuWrapper,
  MenuLink,
  MobileMenuCloseButton,
} from '../styles/headerStyles';
import { XCircle } from 'react-feather';

const MobileMenu = ({ menuItems, closeMenu }) => (
  <MobileMenuBackground>
    <MobileMenuWrapper>
      <MobileMenuCloseButton>
        <XCircle size={30} onClick={closeMenu} />
      </MobileMenuCloseButton>
      {menuItems.map(item => (
        <MenuLink key={item.path}>
          <Link to={item.path}>{item.name}</Link>
        </MenuLink>
      ))}
    </MobileMenuWrapper>
  </MobileMenuBackground>
);

export default MobileMenu;
